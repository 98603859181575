
tr > td.ant-table-cell {
    padding: 10px 5px !important;
  }
  .ant-pagination-total-text {
    display: none !important;
  }
  
  .export-button {
    /* border: solid 1px #0000001f;
  /* padding: 5px 10px 5px 10px; */
    font-size: 20px;
    color: #5d5d5d !important;
  }
  
  #components-table-demo-resizable-column .react-resizable {
    position: relative;
    background-clip: padding-box;
  }
  
  #components-table-demo-resizable-column .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
  }
  
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }
  
  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
  }
  
  .ant-pro-table-list-toolbar-setting-item {
    padding-bottom: 5px;
  }
  
  .more-results {
    cursor: pointer;
    text-transform: capitalize;
    color: gray;
    /* margin: 10px; */
    padding: 0px 10px 0px 12px;
    background: #ebe9eb;
    text-align: center;
  }
  
  .flags {
    float: left;
    margin: 8px;
    text-transform: capitalize;
  }
  
  /* .data-screen p {
    text-align: center;
    width: 25%;
  } */
  
  .regions {
    width: 30%;
    background-color: #edb754;
    color: white;
    padding: 5px;
    box-shadow: 1px 1px 1px 1px #f1e9e9;
    /* margin: 10px 0px !important; */
    float: left;
  }
  
  .countries {
    width: 30%;
    background-color: #5aa35a;
    color: white;
    padding: 5px;
    box-shadow: 1px 1px 1px 1px #f1e9e9;
    /* margin: 10px 0px !important; */
    float: left;
  }
  
  .sites {
    width: 30%;
    background-color: #3983ce;
    color: white;
    padding: 5px;
    box-shadow: 1px 1px 1px 1px #f1e9e9;
  }
  
  .data-screen {
    text-align: center;
    /* width: 25%; */
    /* float: left; */
  }
  
  .data-screen-status {
    width: 30%;
    background-color: #3983ce;
    color: white;
    text-align: center;
    padding: 5px;
    float: left;
    text-transform: capitalize;
  }
  
  
  .tables{
    width: 70%;
    /* margin-bottom:-15px; */
    margin: 10px;
    /* border:solid 1px black; */
     height:17px
  }
  
  .tables > tr > td{
    border: solid 1px #d1d8ffd1;
    padding:5px 0px;
  }
  
  .mbt20{
    margin: 20px 0px !important;
  }
  
  .mbt10{
    margin: 10px 0px !important;
  }
  
  .mb-7{
    margin-bottom: -7px;
  }
  
  .mbt25{
    margin: 25px 0px;
  }
  
  .header > table > tr > td{
    background-color: #3983ce;
    color: white;
  }
  
  .current-date{
    /* border: solid 1px black; */
    /* width: 87%; */
    /* float: left; */
    padding: 5px;
    color: white;
    background: #134e88;
    box-shadow: 1px 0px 1px 0px #1080ed;
  }
  
  
  .current-julian-date{
    padding: 3px;
    color: #000;
    background: #ebf5ff;
    border: solid 2px #3983ce;
    box-shadow: 1px 0px 1px 0px #1080ed;
  
  }
  
  .test table {
    /* padding-left: 10px !important; */
  }
  
  .test table > thead > tr > .ant-table-cell {
    text-align: center;
  }
  
  .test table > tbody > tr > td.ant-table-cell {
    text-align: center;
  }
  
  /* .ant-card-head {
    min-height: 48px;
      margin-bottom: -1px;
      padding: 0 10px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 16px;
      background: #004fa3;
      margin: 5px;
      color: white;
      border-bottom: 1px solid #f0f0f0;
      border-radius: 2px 2px 0 0;
  } */
  
  .ant-card-head-title {
    display: inline-block;
    -ms-flex: 1;
    flex: 1 1;
    padding: 5px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  
  
  /* .components-table-demo-nested tr:nth-child(2n) td {
    background-color: #fbfbfb;
  } */
  /* .components-table-demo-nested thead {
    background-color: #f1f1f1;
  } */
  
  /* .components-table-demo-nested table> tbody> tr{
    background: #e3f1fe;
  } */
  
  .regionTable table tr{
    background-color: #0c3560;
    color: white;
  }
  
  .regionTable table tbody > tr.ant-table-row:hover > td {
    background-color:  #0c3560;;
    color: white;
  }
  
  .regionTable table tr > td> button{
    color: white;
    background: #ed1c2e;
    border: 1px solid #ed1c2e;
  }
  
  .regionTable table tr > td> button:active{
    color: white;
  }
  
  .countryTable table  tr{
    background-color: #e3f1fe;
    color: black;
  }
  
  .countryTable table tbody > tr.ant-table-row:hover > td {
    background-color:  #e3f1fe;
    color: black;
  }
  
  
  .storeTable table thead >tr > th{
    background-color: #e8e8e9 !important;
  }
  
  
  .storeTable table tbody >tr{
    background-color: white !important;
  }
  
  .storeTable table tbody > tr.ant-table-row:hover > td {
    background-color: white;
    color: black;
  }
  
  .storeTable table tbody > tr.ant-table-row:hover > td.ant-table-cell {
    background-color: white;
    color: black;
  }
  
  
  .virtual-table .ant-table-container:before,
    .virtual-table .ant-table-container:after {
      display: none;
    }
    .virtual-table-cell {
      box-sizing: border-box;
      padding: 6px 0px 0px 0px;
      border: 1px solid #f0f0f0;
      background: #FFF;
      text-align: center;
      
    }
   [data-theme="dark"]  .virtual-table-cell {
      box-sizing: border-box;
      padding: 16px;
      border-bottom: 1px solid #303030;
      background: #141414;
    }
  
    input.ant-input.ant-input-sm.julianDate {
      width: 60px;
      padding: 4px 6px;
  }

  .audit-table .ant-table-thead .ant-table-cell {
    background-color: #071d35;
    color: whitesmoke;
  }

  .ant-modal-content {
    background-color: rgb(249, 251, 255);
  }

  .ant-modal-header {
    background-color: rgb(249, 251, 255);
    padding: 5px 5px;
  }

  .ant-modal-body {
    padding: 5px;
  }

  .audit-table-row-cell {
    background-color: #e3f1fe;
  }

  .audit-table-row-cell-error {
    background-color: #ffe8e8;
  }

  .audit-table .ant-table-body {
    border-left: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  .audit-table .ant-table-header{
    border-bottom: solid 1px black;
    border-top: solid 1px black;
    border-left: solid 1px black;
    border-right: solid 1px black;
  }

  .audit-table .ant-table-body .ant-table-row .ant-table-cell {
    border-left: solid 1px white;
    border-bottom: solid 1px white;
    border-right: solid 1px white;
    border-top: solid 1px white;
  }

  .audit-logs-info{
    border: solid 2px #908e8e;
    height: 16%;
    padding: 2px;
    margin-bottom: 5px;
  }