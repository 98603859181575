.tabs-position{
    padding:10px
}

.card__sidebar{
    /* padding:20px 0 0 0; */
    /* margin: 10px 0; */
}

.ant-tabs-tab{
    margin: 0 0 0px 0 !important;
    padding: 8px 4px !important;
    text-align: left !important;
}

.facet-list{
    height: 250px;
    overflow-y: auto;
    margin: 5px 0px;
}

.scrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #bab5b5;
}

.ant-collapse > .ant-collapse-item {
	border-bottom: 1px solid #d9d9d9;
	background: #3983ce;
    color: white;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    text-transform: uppercase;
    padding: 12px 16px 12px 40px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: #0c3560;
    color: white;
}

.sidebar__bg{
	background: #3983ce;
    color: white;
    
    /* margin-top: 10px; */
}

.filter{
    background: #ed1c2e;
    padding: 10px;
    color: white;
    /* margin: 0 0 10px; */
}

.filter-title{
    float: left;
    width: 90%;
    font-weight: 600;
    text-transform: uppercase;
}

.search__button{
    /* border: solid 1px #e8e8e8; */
    padding: 0px 0px 0px 5px;
    /* margin-left: 10px; */
}

.search__button:hover{
    cursor: pointer;
}

.search-input{
    padding: 0px;
}

.data-truncate{
    text-align: center;
    font-size: 12px;
    background: #ebebeb;
    color: black;
    padding: 0px 10px;
}

.sidebar__bg > div.ant-collapse-content > .ant-collapse-content-box{
    background-color: #e3f1fe;
}

.d-md-block{
    display: block;
}


.d-md-none{
    display: none;
}

.collapsed > span {
    position: absolute;
    left: 95%;
    right: -12px;
    height: 20px;
    bottom: -8px;
    top: -8px;
    font-size: 20px;
    z-index: 100;
    cursor: pointer;
}

.toggled >span {
    position: absolute;
    left: -105%;
    right: -12px;
    height: 20px;
    bottom: -8px;
    top: -8px;
    font-size: 20px;
    z-index: 100;
    cursor: pointer;

}

.sidebar-margin{
    width: 95%;
}
.site_name .ant-collapse-content-active{
    height: auto !important;
    max-height: 300px !important;
    overflow: overlay !important;
}