.site-calendar-demo-card {
    width: 300px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
  }

.highlight{
    background: black;
    color:pink
}

.f12{
    font-size: 12px;
}
.f20{
    font-size: 20px;
}
.f18{
    font-size: 18px;
}

.f16{
    font-size: 16px;
}
.f14{
    font-size: 14px;
}


.mb1{
    margin-bottom: 1px;
}

.missingTile{
    background-color: #FFFF00 !important;
    color: black;
    height: 100%;
    width: 100%;
    
}

.warehouseLedgerErrorTile{
    background-color: orchid !important;
    color: black;
}

.oracleLedgerErrorTile{
    background-color: #f8483f !important;
    color: black;
}

.oracleWarehouseErrorTile{
    background-color: #ff4d00 !important;
    color: black;
}

.elowErrorTile{
    background-color: #ba020a !important;
    color: black;
}

.missingTile:hover{
    background-color: #FFFF00 !important;
    color: black  !important;
    height: 100%;
    width: 100%;
    
}

.fileProcessingTile{
    background-color: rgba(221, 12, 12, 0.815) !important;
    color: black;
    
}

.fileProcessingTile:hover{
    background-color: rgba(221, 12, 12, 0.815) !important;
    color: black !important;
    
}

.ledgerErrorTile{
    background-color: red !important;
    color: black;
    
}

.ledgerErrorTile:hover{
    background-color: red !important;
    color: black !important;
    
}

.warehouseErrorTile{
    background-color: orange !important;
    color: black;
    
}

.warehouseErrorTileCN{
    background-color: orange !important;
    color: black;
    font-size: 80%;
}

.warehouseErrorTile:hover{
    background-color: orange !important;
    color: black !important;
    
}

.processingTile{
    background-color: whitesmoke !important;
    color: black;
    
}
.emptyData {
    background-color: white !important;
    color: black;
}

.processingTile:hover{
    background-color: whitesmoke !important;
    color: black !important;
    
}


.closedTile{
    background-color: gray !important;
    color: black;
    
}

.closedTile:hover{
    background-color: gray !important;
    color: black !important;
    
}

.inProgressTile{
    background-color: #8aacfa !important;
    color: black;
    
}
.inProgressTile:hover{
    background-color: #8aacfa !important;
    color: black;
    
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    /* background: white; */
}


.lederWareHouseErrorTile{
    background-color: orchid !important;
    color: black;
    
}

.lederWareHouseErrorTile:hover{
    background-color: orchid !important;
    color: black !important;
    
}

.oracleErrorTile{
    background-color:#e2321e !important;
    color: black;
    
}