.position {
    padding: 10px 0 0 0;
  }
  
  .no-padding {
    padding: 0px !important;
  }
  
  .card__filters {
    padding: 10px;
    margin: 10px 0 0 10px;
  }
  
  .button__export {
    float: right;
    padding: 0 10px 0 0;
  }
  
  .mt60 {
    margin-top: 60px;
  }
  
  .card-container p {
    margin: 0;
  }
  .card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
  }
  .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
    background: #fff;
  }
  .card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .card-container > .ant-tabs-card .ant-tabs-tab,
  [data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .card-container > .ant-tabs-card .ant-tabs-tab-active,
  [data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
  }
  #components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
  }
  [data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
  }
  [data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  [data-theme="dark"] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
  }
  [data-theme="dark"]
    .card-container
    > .ant-tabs-card
    .ant-tabs-content
    > .ant-tabs-tabpane {
    background: #141414;
  }
  [data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #141414;
    border-color: #141414;
  }
  
  
  .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: -8px 0px 0px 0px !important;
  }
  